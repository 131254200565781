import { InMemoryCache } from '@apollo/client';
import { AuthHelpers } from 'helpers'

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return SetIsLoggedInVar();
        },
        cartItems() {
          return SetCartItemsVar();
        },
        isShowSidebar() {
          return setIsShowSidebar();
        },
        clientSelected() {
          return setClientSelected()
        },
        ruleList() {
          return SetRuleList();
        },
        ruleCurrent() {
          return SetRuleCurrent();
        },
        referValidateCount() {
          return SetReferValidateCount();
        },
        referPaidCount() {
          return SetReferPaidCount();
        },
        dashBoardDepartment() {
          return SetDashBoardDepartment();
        },
        isNotification() {
          return SetNotificationStatus();
        },
      }
    }
  }
});

// isLogin
export const SetIsLoggedInVar = cache.makeVar<boolean>(!!AuthHelpers.checkAuthen());

// Set Rule List
export const SetRuleList = cache.makeVar<string[]>(AuthHelpers.getRuleList());

// Set Rule Current
export const SetRuleCurrent = cache.makeVar<number>(0);

// Cart Items  
export const SetCartItemsVar = cache.makeVar<string[]>([]);

// Set isShowSidebar
export const setIsShowSidebar = cache.makeVar(true);

// Set Client Selected
export const setClientSelected = cache.makeVar("");

// Set Reference Validate Count
export const SetReferValidateCount = cache.makeVar<object>({
  refAll: 0,
  refNew: 0,
  refUsed: 0
});

// Set Reference Validate Count
export const SetReferPaidCount = cache.makeVar<object>({
  refAll: 0,
  refNew: 0,
  refUsed: 0
});

// Set DEPARTMENT of Dashboard
export const SetDashBoardDepartment = cache.makeVar<number>(0);

// Set Notification
export const SetNotificationStatus = cache.makeVar<boolean>(false);