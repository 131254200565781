import React from "react";
import * as _ from "lodash";
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";

const FilterByAdvisor = (props: any) => {
    const { listAdvisor, onChangeFilterByAdvisor, parent, t } = props;
    return (
        <CRow>
            <CCol xs="12" sm="auto">
                <CFormGroup className="form-filter showing-record">
                    <CSelect className="select-filter" name="byClient" id="byClient" onChange={(e: any) => onChangeFilterByAdvisor(e.target.value)}>
                        <option value="" selected={parent === ""}>{t('AdminLayout.All Advisor')}</option>
                        <option value="null" selected={parent === "null"}>{t('AdminLayout.Without Advisor')}</option>
                        {listAdvisor &&
                            !_.isEmpty(listAdvisor) &&
                            listAdvisor.map(
                                (o: any, key: number) =>
                                    o &&
                                    o.user && (
                                        <option key={key} value={o.user.id} selected={o.user.id === parent}>
                                            {o.user.firstName + " " + o.user.lastName}
                                        </option>
                                    )
                            )}
                    </CSelect>
                </CFormGroup>
            </CCol>
        </CRow>
    );
};

export default (withTranslation)()(FilterByAdvisor);
