import React from "react";
import { CFormGroup, CSelect } from "@coreui/react";
import { withTranslation } from "react-i18next";

const SortByCreatedAt = ({ setSortByHandle, t }: any) => {
    return (
        <CFormGroup className="showing-record">
            <CSelect className="select-record" name="records" id="records" onChange={(e: any) => setSortByHandle(e.target.value)}>
                <option value="-1">{t('AdminLayout.Recent')}</option>
                <option value="1">{t('AdminLayout.Oldest created')}</option>
            </CSelect>
        </CFormGroup>
    );
};

export default (withTranslation)()(SortByCreatedAt);
