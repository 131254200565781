import React from "react";
import * as _ from "lodash";
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";
import { Const } from 'constants/index'

const FilterByRoles = (props: any) => {
    const { onChangeFilterByRole, t } = props; 
    return (
        <CRow>
            <CCol>
                <CFormGroup className="form-filter">
                    <CSelect
                        className="select-filter"
                        name="byClient"
                        id="byClient"
                        onChange={(e: any) => onChangeFilterByRole(_.toNumber(e.target.value))}
                    >
                        <option value="">{t('AdminLayout.AdviserFields.All Role')}</option>
                        {_.map(_.orderBy(Const.groups, ['filterIndex']), (o: any, key: number) => o.id !== 1 && o.id !== 2 && o.isLogin && <option key={key} selected={o.id === 6} value={o.id}>{t('AdminLayout.Const.Groups.' + o.name)}</option>)}
                    </CSelect>
                </CFormGroup>
            </CCol>
        </CRow>
    );
};

export default (withTranslation)()(FilterByRoles);
