import React from "react";
import * as _ from "lodash";
import { CFormGroup, CSelect } from "@coreui/react";

const ShowingNumberRecords = ({ setLimitHandle }: any) => {
    return (
        <CFormGroup className="showing-record">
            <CSelect className="select-record" name="records" id="records" onChange={(e: any) => setLimitHandle(_.toNumber(e.target.value))}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </CSelect>
        </CFormGroup>
    );
};

export default ShowingNumberRecords;
