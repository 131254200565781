// User
export const MS0003 = 'Field must be a valid email';
export const MS0005 = 'Field must be a number';
export const MS1001 = 'Field is required';

export const MS1004 = 'Update profile success';
export const MS1008 = 'Passwords must match.';
export const MS1009 = 'Password changed successfully, please login again after 3 seconds';
export const MS1010 = 'Your account is not allowed to login'

// Modal
export const MS2001 = 'Delete Success';
export const MS2002 = 'Update Success';
export const MS2003 = 'Create Success';

// Reference
export const MS5003 = 'Update reference successfuly'
export const MS5004 = 'Update status successfuly'
export const MS5005 = 'Change to ambassador successfuly'
export const MS5006 = 'Update status fail'
export const MS5007 = 'Please update ambassadors rewards by vehicle type.'

// Create Password Form
export const MS6002 = 'Passwords must match'
export const MS6003 = 'Create Password Success.';
export const MS6004 = 'Your Password is missing one lowercase';
export const MS6005 = 'Your Password is missing one uppercase';
export const MS6006 = 'Your Password is missing one number';
export const MS6007 = 'Your Password is missing one Symbol';
export const MS6008 = 'Your Password is missing min 8 characters';

// Import Data
export const MS7001 = 'Import success';
export const MS7002 = 'Import fail';