import React from "react";
import * as _ from "lodash";
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";
import Utils from 'utils'

const FilterByDepartment = (props: any) => {
    const { onChangeFilterByDepartment, t, available, loginDeparts } = props;
    const list = available && !_.isEmpty(available) ? _.filter(Utils.listDepartments(t), (o: any) => _.includes(available, o.id)) : Utils.listDepartments(t);
    const selected = loginDeparts && _.size(loginDeparts) === 1 ? _.find(list, (o: any) => o.id === loginDeparts[0]) : null;
    return (
        <CRow>
            <CCol>
                <CFormGroup className="form-filter filter-department">
                    <CSelect
                        className="select-filter"
                        name="byClient"
                        id="byClient"
                        onChange={(e: any) => onChangeFilterByDepartment(_.toNumber(e.target.value))}
                    >
                        <option value="">{t('AdminLayout.AdviserFields.All Department')}</option>
                        {_.map(list, (o: any, key: number) => <option key={key} value={o.id} selected={selected && selected.id === o.id}>{o.name}</option>)}
                    </CSelect>
                </CFormGroup>
            </CCol>
        </CRow>
    );
};

export default (withTranslation)()(FilterByDepartment);
