import React from 'react'
import {
  CCol,
  CContainer,
  CRow,
  CImg
} from '@coreui/react'

const NotFound = () => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="12">
            <div className="page-404">
              <div className="page-404-inner">
                <div className="logo-404">
                  <CImg src="/images/raf-logo-not-found.svg" />
                </div>
                <div className="text-404">
                  <h3>Oups!</h3>
                  <div className="desc">
                    Désolés, la page que vous recherchez est introuvable.
                  </div>
                  <div className="link"><a href="/">Retour à la page d'accueil</a></div>              
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default NotFound
