import React from "react";
import { CFormGroup, CSelect } from "@coreui/react";
import { withTranslation } from "react-i18next";
import * as _ from 'lodash';

const FilterByCreateStatus = ({ setFilterByCreateStatus, t }: any) => {
    return (
        <CFormGroup>
            <CSelect className="select-record" name="records" id="records" onChange={(e: any) => setFilterByCreateStatus(_.toNumber(e.target.value))}>
                <option value="">{t('AdminLayout.AmbassadorFields.All')}</option>
                <option value="1">{t('AdminLayout.AmbassadorFields.SubFields.Registration by import')}</option>
                <option value="2">{t('AdminLayout.AmbassadorFields.SubFields.Registration by advisor')}</option>
                <option value="3">{t('AdminLayout.AmbassadorFields.SubFields.Registration by client')}</option>
                <option value="4">{t('AdminLayout.AmbassadorFields.SubFields.Registration by other')}</option>
            </CSelect>
        </CFormGroup>
    );
};

export default (withTranslation)()(FilterByCreateStatus);
