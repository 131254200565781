import * as _ from "lodash";
import { Const } from "constants/index";
import { useLocation, useHistory } from "react-router-dom";
import { NotificationHelpers } from "helpers";
import Axios from "axios";
import fileDownload from "js-file-download";

class Utils {
    public getLang = () => {
        const location = useLocation();
        const language = location.pathname.split("/");
        return language[1] && _.includes(Const.locates, language[1]) ? language[1] : "fr";
    };

    public transUrl = (url: string) => {
        const language = this.getLang();
        return "/" + language + url;
    };

    public linkTrans = (lng: string, location: any) => {
        // const location = useLocation();
        const language = location.pathname.split("/");
        if (lng === "fr") {
            if (!_.includes(Const.locates, language[1])) return false;
            location.pathname = _.replace(location.pathname, "/" + language[1], "/fr");
        } else if (_.includes(Const.locates, language[1])) {
            location.pathname = _.replace(location.pathname, language[1], lng);
        } else {
            location.pathname = "/" + lng + location.pathname;
        }
        return location;
    };
    public handleNotification = (res: any, message: string) => {
        if (!res.errors)
            NotificationHelpers.showSuccess({
                message,
            });
        else {
            NotificationHelpers.showError({
                message: res.errors[0].message,
            });
        }
    };
    public setMapKeyValue(data: any, t: any, mss = "", name = "name") {
        const obj = _.map(data, (o): any => _.pick(o, ["id", name]));
        const keyMap = {
            id: "value",
            [name]: "label",
        };
        // if (!_.find(obj, ['id', null]))
        //     obj.unshift({ id: null, [name]: mss })
        /* Convert 2 key [id, title] to [value, label ] do display in multiple select*/
        return obj.map((obj: any) => {
            const employee = _.mapKeys(obj, (value, key) => keyMap[key]);
            const transText = "AdminLayout.MassMailing." + employee.label;
            employee.label = t(transText);
            return employee;
        });
    }

    public download = (url: string, filename: string) => {
        Axios.get(url, {
            responseType: "blob",
        })
            .then((res: { data: string | ArrayBuffer | ArrayBufferView | Blob }): any => fileDownload(res.data, filename))
            .catch((err) => console.log(err));
    };

    public getRoleName = (role: Number) => {
        const roleAccess = _.find(Const.groups, (o: any) => o.id === role);
        return roleAccess ? roleAccess.name : "";
    };

    public initReward = () => {
        let initReward = [];
        const price_default = [100, 100, 150, 150, 200, 200, 250, 250, 300, 300];
        const total_default = [100, 200, 350, 500, 700, 900, 1150, 1400, 1700, 2000];
        for (let i = 1; i <= 10; i++) {
            initReward.push({ number: i, price: price_default[i - 1], total: total_default[i - 1] });
        }
        return initReward;
    };

    public removeTypename = (data: any) => {
        return data && _.map(data, (o) => _.omit(o, "__typename"));
    };

    public getRoleNameMulti = (roles: any) => {
        return _.map(roles, (role: any, key: number) => {
            const roleAccess = _.find(Const.groups, (o: any) => o.id === _.toNumber(role));
            return roleAccess ? roleAccess.name + (_.size(roles) > 1 && key < _.size(roles) - 1 ? " & " : "") : "";
        });
    };

    public getListRuleDisabled = (rules: any) => {
        if (rules && !_.isEmpty(rules)) {
            if (_.includes(rules, "7") || _.includes(rules, "8")) {
                return [2, 3, 4, 5, 6];
            } else {
                return _.filter([2, 3, 4, 5, 6, 7, 8], (o) => o !== _.toNumber(rules[0]));
            }
        } else return [];
    };

    public getListRuleHidden = (rules: any) => {
        if (rules && !_.isEmpty(rules)) {
            if (_.includes(rules, "5")) {
                return [6];
            }
        }
        return [];
    };

    public getListRuleDisabled2 = (rules: any) => {
        if (rules && !_.isEmpty(rules)) {
            if (_.includes(rules, "1")) {
                return [0];
            } else {
                return _.filter([1], (o) => o !== _.toNumber(rules[0]));
            }
        } else return [];
    };

    public getListRuleHidden2 = (rules: any) => {
        if (rules && !_.isEmpty(rules)) {
            if (_.includes(rules, "1")) {
                return [2];
            }
        }
        return [];
    };

    public getRuleOfUserLogin = (user: any, client: any) => {
        if (user) {
            if (!_.isEmpty(user.rule_list)) {
                const userGroupRule = _.find(user.rule_list, (o) => o.client && o.client.id === client);
                if (userGroupRule && userGroupRule.rule >= 6) {
                    return user.id;
                } else return "";
            }
            return ""; // Saiyan
        }
        return "";
    };

    public getUserRule = (user: any, client: any) => {
        const userGroupRule = this.getGroupRuleLogin(user, client);
        if (userGroupRule && userGroupRule.rule) {
            return userGroupRule.rule;
        }
        return "";
    };

    public getGroupRuleLogin = (user: any, client: any) => {
        if (user) {
            if (!_.isEmpty(user.rule_list)) {
                const userGroupRule = _.find(user.rule_list, (o) => o.client && o.client.id === client);
                if (userGroupRule) {
                    return userGroupRule;
                }
                return "";
            }
            return ""; // Saiyan
        }
        return "";
    };

    public getGrandParentId = (idUser: any, parents: any) => {
        if (idUser && parents) {
            const user = _.find(parents, (o) => o.user && o.user.id === idUser);
            if (user && user.parent) {
                return user.parent.id;
            }
        }
        return null;
    };

    public convertToQueries = (query: any, params: any) => {
        const moreSpec: any = _.pickBy(params, _.identity);
        return { ...query, ...moreSpec };
    };

    public getValueFromCustomField = (customFields: any, key: String, lng: String) => {
        const result = _.find(customFields, (o) => o.key === key && o.language === lng);
        if (result) {
            switch (result.__typename) {
                case "MediaValue":
                    return (result.media && result.media.url) || null;
                case "ArrayValue":
                    return result.arrValue;
                default:
                    return result.value;
            }
        }
        return "";
    };

    public getPricesOfType = (rewards: any, type: string) => {
        const list = rewards && !_.isEmpty(rewards) ? _.find(rewards, (o: any) => o.type === type) : [];
        if (list && !_.isEmpty(list)) return list.prices;
        return [];
    };

    public getDivisionDisable = (division: any) => {
        if (division && _.isArray(division) && !_.isEmpty(division)) {
            if (_.includes(division, "1")) {
                return [2];
            } else {
                return [1];
            }
        }
        if (division && !_.isArray(division)) {
            if (division === 1) {
                return [2];
            } else {
                return [1];
            }
        }
        return [];
    };

    public listPhoneTypes = (t: any) => {
        return _.map(Const.phoneTypes, (o: any) => {
            const transText = "AdminLayout.Const.PhoneTypes." + o.name;
            return { ...o, name: t(transText) };
        });
    };

    public listGroups = (t: any) => {
        return _.map(Const.groups, (o: any) => {
            const transText = "AdminLayout.Const.Groups." + o.name;
            return { value: o.id, name: t(transText), isLogin: o.isLogin ? true : false };
        });
    };

    public listRewardTypes = (t: any) => {
        return _.map(Const.departments, (o: any) => {
            const transText = "AdminLayout.Const.departments." + o.name;
            return { value: o.id, name: t(transText) };
        });
    };

    public listDepartments = (t: any) => {
        return _.map(Const.departments, (o: any) => {
            const transText = "AdminLayout.Const.departments." + o.name;
            return { ...o, name: t(transText) };
        });
    };

    public listConversionAmbassador = (t: any) => {
        return _.map(Const.ConversionAmbassador, (o: any) => {
            const transText = "AdminLayout.Const.ConversionAmbassador." + o.name;
            return { value: o.id, name: t(transText) };
        });
    };

    public listAmbassadorStatus = (t: any) => {
        return _.map(Const.AmbassadorStatus, (o: any) => {
            const transText = "AdminLayout.Const.AmbassadorStatus." + o.name;
            return { value: o.id, name: t(transText) };
        });
    };

    public listStatusFollowUp = (t: any) => {
        return _.map(Const.statusFollowUp, (o: any) => {
            const transText = "AdminLayout.Const.statusFollowUp." + o.name;
            return { value: o.id, name: t(transText) };
        });
    };

    public getAdvisorSelected = (userLogin: any, rule: any) => {
        const rule_list = userLogin && !_.isEmpty(userLogin.rule_list) ? userLogin.rule_list : [];
        const ruleCurrent = rule && rule.data && rule.data.ruleCurrent;
        if (_.includes(Const.canBeAdvisor, ruleCurrent)) {
            const advisorSelected = _.find(rule_list, (o: any) => o.rule === ruleCurrent);
            return advisorSelected && advisorSelected.user && _.isObject(advisorSelected.user) ? advisorSelected.user.id : advisorSelected.user;
        }
        return null;
    };

    public transToListOfRadio = (list: any) => {
        return _.map(list, (o: any) => {
            return {
                name: o.name,
                value: o.id,
            };
        });
    };

    public checkFofAccess = (rule: number, listAccess: any) => {
        return _.includes(listAccess, rule);
    };

    public getFavicon = (value: number) => {
        const favicon = _.find(Const.favicons, (o: any) => o.value === value);
        return favicon ? favicon.src : null;
    };

    public getFileExt = (filename: string) => {
        if (filename) {
            return filename.split(".").pop();
        }
        return null;
    };

    public convertToKeywords = (user: any) => {
        const username = user && user.username ? user.username : "";
        const firstName = user && user.firstName ? user.firstName : "";
        const lastName = user && user.lastName ? user.lastName : "";
        const phone = user && user.phone ? user.phone : "";
        return username + " " + firstName + " " + lastName + " " + phone;
    };

    public convertToListDataUsers = (list: any) => {
        return _.map(list, (o: any) => {
            return {
                ...o.user,
                rule_list: [{ ..._.omit(o, ["user"]) }],
            };
        });
    };
    public convert2Number = (number: any) => {
        return number && number.length > 1 ? number : `0${number}`;
    };

    public translateFormat = (list: any, t: any) => {
        return _.map(list, (o: any) => {
            const transText = "AdminLayout.Const.statusUser." + o.name;
            return { value: o.id, name: t(transText) };
        });
    };

    public isMaintenance = async (setup = false) => {
        if (setup === true) {
            const history = useHistory();
            const publicIp = require("public-ip");
            (async () => {
                const ipClient = await publicIp.v4();
                if (_.includes(Const.ipMaintenance, ipClient) === false) {
                    console.log("ipClient", ipClient);
                    return history.push("/maintenance");
                }
            })();
        }
    };

    public checkAmbassadorsNoExists = (item: any) => {
        if (item && item.ambassador_list && !_.isEmpty(item.ambassador_list)) {
            const list = item.ambassador_list;
            const client = item.client;
            if (_.find(list, (o: any) => o.client && client && o.client.id === client.id && o.rule === 7)) return false;
        }
        return true;
    };
    public getLastMonths = (data: any, maxCount: number) => {
        let now = new Date();
        let theMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let getAmbMonths: any = [];
        for (let i = 11; i >= 0; i--) {
            const future = new Date(now.getFullYear(), now.getMonth() - i, 1);
            const month = theMonths[future.getMonth()];
            const year = future.getFullYear();

            const index: any = _.findIndex(getAmbMonths, (o: any) => o.year === year);
            const getCount = data && _.find(data, (o) => o._id === year + "-" + ("0" + (future.getMonth() + 1)).slice(-2));
            const getValue = (getCount && getCount.count) || 0;
            if (index >= 0) {
                const currentMonth = getAmbMonths[index].months;
                getAmbMonths[index] = {
                    year,
                    months: currentMonth.concat({
                        month,
                        count: getValue,
                        height: _.round((180 * getValue) / maxCount || 1),
                    }),
                };
            } else {
                getAmbMonths.push({
                    year,
                    months: [
                        {
                            month,
                            count: getValue,
                            height: _.round((180 * getValue) / maxCount || 1),
                        },
                    ],
                });
            }
        }

        return getAmbMonths;
    };

    public formatBytes = (bytes: number, decimals = 1) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    public listRegisteredBy = (t: any) => {
        return _.map(Const.registeredBy, (o: any) => {
            const transText = "AdminLayout.AmbassadorFields.SubFields." + o.name;
            return { ...o, name: t(transText) };
        });
    };

    public omitDeep = (obj: any, key: any) => {
        const keys = Object.keys(obj);
        const newObj: any = {};
        keys.forEach((i) => {
            if (i !== key) {
                const val = obj[i];
                if (Array.isArray(val)) newObj[i] = this.omitDeepArrayWalk(val, key);
                else if (typeof val === "object" && val !== null) newObj[i] = this.omitDeep(val, key);
                else newObj[i] = val;
            }
        });
        return newObj;
    };

    public omitDeepArrayWalk = (arr: any, key: any) => {
        return arr.map((val: any) => {
            if (Array.isArray(val)) return this.omitDeepArrayWalk(val, key);
            else if (typeof val === "object") return this.omitDeep(val, key);
            return val;
        });
    };

    public validateEmail = (email: any) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    public checkAdvisorCreated = (parent: any, ruleCurrent: any, advisorSelected: any) => {
        if (parent && ruleCurrent && ruleCurrent.data && advisorSelected) {
            if (parent === advisorSelected && _.includes(Const.canBeAdvisor, ruleCurrent.data.ruleCurrent)) return true;
        }
        return false;
    };

    public diff_years(dt2: any, dt1: any) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60 * 60 * 24;
        return Math.abs(diff / 365.25);
    }

    public getDepartments = (user: any, client: any) => {
        if (user) {
            if (!_.isEmpty(user.rule_list)) {
                const userGroupRule = _.find(user.rule_list, (o) => o.client && o.client.id === client);
                return userGroupRule ? userGroupRule.department && userGroupRule.department.sort(): [];
            }
            return []; // Saiyan
        }
        return [];
    };

    public getListDepartmentOfReference = (t: any) => {
        return _.filter(this.listRewardTypes(t), (o: any) => o.value !== 3 && o.value !== 4);
    };

    public getListDisabledOfReference = (t: any, loginDeparts: any) => {
        if (!_.isEmpty(loginDeparts)) {
            const result =  _.filter(this.getListDepartmentOfReference(t), (o: any) => !_.includes(loginDeparts, o.value));
            if (result && result.length === 2) {
                return [];
            }
            return result;
        }
        return [];
    };

    public getAmbassador = (user: any) => {
        if (user) {
            return user.firstName + " " + user.lastName;
        } else return "//";
    };
    public getAdvisor = (user: any) => {
        if (user && user.rule_list && !_.isEmpty(user.rule_list) && user.rule_list[0].parent) {
            return user.rule_list[0].parent.firstName + " " + user.rule_list[0].parent.lastName;
        } else return "//";
    };

    public getStatus = (value: number, t: any) => {
        const status: any = _.find(Const.statusReferenceUser, (o: any) => o.id === value);
        const name = status ? t("AdminLayout.Const.statusReferenceUser." + status.name) : "";
        return name;
    };

    public getDepartment = (value: any, t: any) => {
        const deps = _.map(value, (o: any) => {
            const dep = _.find(Const.departments, (p: any) => p.id === o);
            const name = dep ? t("AdminLayout.Const.departments." + dep.name) : "";
            return name;
        });
        return _.join(deps, ", ");
    };

    public getRule = (value: number) => {
        const group: any = _.find(Const.groups, (o: any) => o.id === value);
        return group ? group.name : "";
    };

    public countAmbassador = (list: any, client: any) => {
        const size = _.size(_.filter(list, (o: any) => o.client && o.client.id === client));
        if (size > 0) {
            return size;
        }
        return 0;
    };

    public countReferences = (list: any, client: any) => {
        if (list && !_.isEmpty(list)) {
            return _.sumBy(list, (o: any) => _.size(_.filter(o.child_refers, (p: any) => p.client && p.client.id === client && p.status !== 4)));
        }
        return 0;
    };

    public countAmbForCreatedBy = (list: any, client: any) => {
        if (list && !_.isEmpty(list)) {
            return {
                import: _.size(_.filter(list, (o: any) => o && o.client && o.client.id === client && o.createdBy === 1)),
                advisor: _.size(_.filter(list, (o: any) => o && o.client && o.client.id === client && o.createdBy === 2)),
                client: _.size(_.filter(list, (o: any) => o && o.client && o.client.id === client && o.createdBy === 3)),
                other: _.size(_.filter(list, (o: any) => o && o.client && o.client.id === client && o.createdBy === 4)),
            };
        }
        return { import: 0, advisor: 0, client: 0, other: 0 };
    };

    public countRefbyStatus = (list: any, client: any) => {
        if (list && !_.isEmpty(list)) {
            const _list: any = _.filter(list, (o: any) => o && o.client && o.client.id === client);
            return {
                saleToValidate: _.sum(_.map(_list, (o: any) => _.size(_.filter(o.child_refers, (p: any) => p && p.status === 1)))),
                rewardToPay: _.sum(_.map(_list, (o: any) => _.size(_.filter(o.child_refers, (p: any) => p && p.status === 2)))),
                rewardPaid: _.sum(_.map(_list, (o: any) => _.size(_.filter(o.child_refers, (p: any) => p && p.status === 3)))),
                saleCancel: _.sum(_.map(_list, (o: any) => _.size(_.filter(o.child_refers, (p: any) => p && p.status === 4)))),
            };
        }
        return { saleToValidate: 0, rewardToPay: 0, rewardPaid: 0, saleCancel: 0 };
    };

    public referenceExportFields = (results: any, t: any) => {
        return _.map(results, (o: any) => {
            return {
                "First Name": o && o.user ? o.user.firstName : "",
                "Last Name": o && o.user ? o.user.lastName : "",
                Email: o && o.user ? o.user.username : "",
                Ambassador: o && o.parent ? this.getAmbassador(o.parent) : "//",
                Advisor: o && o.parent ? this.getAdvisor(o.parent) : "//",
                Status: o && o.status ? this.getStatus(o.status, t) : "",
                Date:
                    o && o.createdAt
                        ? new Date(o.createdAt).toLocaleString([], {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour12: false,
                          })
                        : "",
                Group: o && o.group ? o.group.name : "//",
                Client: o && o.client ? o.client.name : "//",
            };
        });
    };

    public advisorExportFields = (results: any, t: any) => {
        return _.map(results, (o: any) => {
            const ambCreatedByList: any =
                o && o.client ? this.countAmbForCreatedBy(o.child, o.client.id) : { import: 0, advisor: 0, client: 0, other: 0 };
            const refStatus: any =
                o && o.client ? this.countRefbyStatus(o.child, o.client.id) : { saleToValidate: 0, rewardToPay: 0, rewardPaid: 0, saleCancel: 0 };
            return {
                "Full Name": o && o.user ? o.user.firstName + " " + o.user.lastName : "",
                Username: o && o.user ? o.user.username : "",
                Department: o && o.department ? this.getDepartment(o.department, t) : "",
                "Registration by import": ambCreatedByList.import,
                "Registration by advisor": ambCreatedByList.advisor,
                "Registration by client": ambCreatedByList.client,
                "Registration by other": ambCreatedByList.other,
                "Number of ambassadors": o && o.client && _.includes(Const.canBeAdvisor, o.rule) ? this.countAmbassador(o.child, o.client.id) : "//",
                "Sale to validate": refStatus.saleToValidate,
                "Reward to pay": refStatus.rewardToPay,
                "Reward paid": refStatus.rewardPaid,
                "Sale cancel": refStatus.saleCancel,
                "Reference completed": o && o.client && _.includes(Const.canBeAdvisor, o.rule) ? this.countReferences(o.child, o.client.id) : "//",
                Status: o && o.active ? "Acitve" : "Unactive",
                "Last login":
                    o && o.lastLogin
                        ? new Date(o.lastLogin).toLocaleString([], {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour12: false,
                          })
                        : "",
                "Created on":
                    o && o.createdAt
                        ? new Date(o.createdAt).toLocaleString([], {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour12: false,
                          })
                        : "",
                Group: o && o.group ? o.group.name : "//",
                Client: o && o.client ? o.client.name : "//",
            };
        });
    };

    public getValueFromReport = (array: any, value: any) => {
        const getObject = _.size(array) && _.find(array, (o) => o._id === value);
        if (getObject) return getObject.count;
        return 0;
    };

    public isSylvainNextAdmin = (userLogin: any) => {
        return userLogin && userLogin.rule === 1;
    }
    
    public employesExportFields = (results: any, t: any) => {
        return _.map(results, (o: any) => {
            return {
                "First Name": o && o.user ? o.user.firstName : "",
                "Last Name": o && o.user ? o.user.lastName : "",
                Email: o && o.user ? o.user.username : "",
                Role: o && o.rule && this.getRoleName(o.rule),
                Group: o && o.group ? o.group.name : "//",
                Client: o && o.client ? o.client.name : "//",
            };
        });
    };

    public getRole = (role: number) => {
        const _role: any = _.find(Const.groups, (o: any) => o && o.id === role);
        return _role && _role.name;
    }

    public getUserGroupRuleActive = (userLogin: any, client: any, role: number) => {
        if (userLogin && userLogin.rule_list) {
            const _role = _.find(userLogin.rule_list, (o: any) => o && o.client && o.client.id === client && o.rule === role);
            return _role;
        }
        return null;
    }
}

export default new Utils();
