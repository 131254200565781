import Cookie from "js-cookie";
import { Const } from "../constants";
import * as _ from "lodash";
import jwt from "jwt-decode";

class AuthHelpers {
    public checkAuthen = (req?: any) => {
        let token, expirationDate;
        /**
         * req: for server site when reloading page
         */
        try {
            token = this.getCookie("jwt", req);
            expirationDate = this.getCookie("expirationDate", req);
            if (!_.isNull(token) && !_.isUndefined(token)) {
                //let user: IUser = jwt(token);
                /*
                  if outside expirationDate
                  or role is not exits in const
                */
                if (new Date().getTime() > +expirationDate) {
                    //!_.find(Const.userRoles, ['id', +user.role])
                    return false;
                }

                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    public checkRuleWithLogin = (token: any, clientSelected?: any) => {
        try {
            let user: any = jwt(token);
            const { rule_list }: any = user;
            if (rule_list && !_.isEmpty(rule_list)) {
                const ruleAccessLogin = _.find(rule_list, (o: any) => o.active === true && o.rule < 7);
                if (!ruleAccessLogin) return { isLogin: false, rule_list: null };
                // Check active
                const listRuleActive = _.sortBy(
                    _.filter(rule_list, (o: any) => o.active === true && o.client && o.client.active && o.group && o.rule && o.isLogin),
                    ["rule"]
                );
                if (listRuleActive && !_.isEmpty(listRuleActive)) {
                    const ruleActive = listRuleActive[0];
                    this.setLoginInfo(token);
                    this.setCookie("rule", ruleActive.rule);
                    this.setCookie("group", ruleActive.group.id);
                    if (clientSelected && _.find(listRuleActive, (o: any) => o.client && o.client.id === clientSelected))
                        this.setCookie("client", clientSelected);
                    else this.setCookie("client", ruleActive.client.id);
                    this.setCookie("rule_list", JSON.stringify(listRuleActive));
                    return { isLogin: true, rule_list: listRuleActive, ruleActive: ruleActive.rule };
                }
                return { isLogin: false, rule_list: null, ruleActive: null };
            }
            // Super Admin
            if (user.rule === 1) {
                this.setLoginInfo(token);
                this.setCookie("rule", 1);
                return { isLogin: true, rule_list: null, ruleActive: 1 };
            }
            return { isLogin: false, rule_list: null, ruleActive: null };
        } catch (error) {
            console.log(error);
            return { isLogin: false, rule_list: null, ruleActive: null };
        }
    };

    public getRuleList = () => {
        let ruleList = this.getCookie("rule_list", null);
        if (ruleList) return JSON.parse(ruleList);
        return [];
    };

    public setLoginInfo = (token: string) => {
        if (token) {
            this.setCookie("jwt", token); // for ssr
            /* expirationDate: 24hours */
            this.setCookie("expirationDate", new Date().getTime() + Const.expireDate * 1000);
        }
    };

    public logout = () => {
        this.removeCookie("jwt");
        this.removeCookie("expirationDate");
        this.removeCookie("group");
        this.removeCookie("client");
        this.removeCookie("rule");
        this.removeCookie("rule_list");
        return true;
    };

    public getToken(name: string) {
        let token;
        token = localStorage.get(name) || null;
        return token;
    }

    public removeLocalStorage(key: string) {
        localStorage.removeItem(key);
    }

    public setToken(name: string, value: any) {
        localStorage.setItem(name, value);
    }

    public setCookie(name: string, value: any) {
        Cookie.set(name, value);
    }

    public getUserLogin() {
        const token = this.getCookie("jwt");
        const expirationDate = this.getCookie("expirationDate");
        if (new Date().getTime() < +expirationDate && !_.isNull(token) && !_.isUndefined(token)) {
            const userLogin = jwt(token);
            return userLogin;
        }
    }

    public getCookie(name: string, req?: any) {
        let value: any;
        if (req) {
            if (!req.headers.cookie) return;

            const jwtCookie = req.headers.cookie.split(";").find((c: any) => c.trim().startsWith(name + "="));

            if (!jwtCookie) return;
            value = jwtCookie.split("=")[1];
        } else {
            value = Cookie.get(name);
        }

        return value;
    }

    public removeCookie(name: string) {
        Cookie.remove(name);
    }
}

export default new AuthHelpers();
