import React from 'react';
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow, CInputCheckbox } from "@coreui/react";

const HideInactive = (props: any) => {
    const { t, handleHideInactive, checked } = props;
    return (
        <div className="request-form-wrap label-display-by advisors-wrap">
            <CRow className="custom-checkbox">
                <CCol>
                    <CFormGroup className="form-filter mb-0">
                        <CInputCheckbox
                            custom
                            label={t("AdminLayout.AdviserFields.Hide inactive advisors")}
                            id="hide-inactive"
                            className="hide-inactive"
                            onClick={handleHideInactive}
                            value={1}
                            checked={checked}
                        />
                        <label htmlFor="hide-inactive" className="custom-control-label lbl-hideinactive">{t("AdminLayout.AdviserFields.Hide inactive advisors")}</label>
                    </CFormGroup>
                </CCol>
            </CRow>
        </div>
    );
};

export default (withTranslation)()(HideInactive); 

