import React from "react";
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";

const SwitchReport = (props: any) => {
    const { onSwitchReport, selected, t } = props;
    return (
        <CRow>
            <CCol xs="12" sm="auto">
                <CFormGroup className="form-filter showing-record">
                    <CSelect className="select-filter" name="byClient" id="byClient" onChange={(e: any) => onSwitchReport(e.target.value)}>
                        <option value="1" selected={selected === 1}>{t('AdminLayout.Performance')}</option>
                        <option value="2" selected={selected === 2}>{t('AdminLayout.References management')}</option>
                    </CSelect>
                </CFormGroup>
            </CCol>
        </CRow>
    );
};

export default (withTranslation)()(SwitchReport);
