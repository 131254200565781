import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow } from "@coreui/react";
import en from "date-fns/locale/en-CA";
import fr from "date-fns/locale/fr-CA";
import Utils from "utils";
import CIcon from '@coreui/icons-react';

const FilterByDate = (props: any) => {
    const { t, setFilter, createdAt } = props;
    return (
        <CRow>
            <CCol>
                <CFormGroup className="form-filter filter-department mr-0">
                    <DatePicker
                        selected={(createdAt && createdAt.fromDate) || null}
                        onChange={(date: any) => setFilter({
                            fromDate: date,
                            toDate: createdAt.toDate || null
                        })}
                        placeholderText={t('AdminLayout.Start date')}
                        isClearable
                        className="filter-date"
                        locale={Utils.getLang().toString() === 'en' ? en : fr}
                        id="fromdate"
                    />
                    {
                        (!createdAt.fromDate) && <label className="icon iconCalendar calendar" style={{ fontSize: '20px' }} htmlFor='fromdate'>
                            <CIcon name="cil-calendar" />
                        </label>
                    }
                </CFormGroup>
            </CCol>
            <CCol>
                <CFormGroup className="form-filter filter-department ml-0">
                    <DatePicker
                        selected={(createdAt && createdAt.toDate) || null}
                        onChange={(date: any) => setFilter({
                            fromDate: createdAt.fromDate || null,
                            toDate: date
                        })}
                        placeholderText={t('AdminLayout.End date')}
                        isClearable
                        className="filter-date"
                        locale={Utils.getLang().toString() === 'en' ? en : fr}
                        id="toDate"
                    />
                    {
                        !createdAt.toDate && <label className="icon iconCalendar calendar" style={{ fontSize: '20px' }} htmlFor='toDate'>
                            <CIcon name="cil-calendar" />
                        </label>
                    }
                </CFormGroup>
            </CCol>
        </CRow>
    );
};

export default (withTranslation)()(FilterByDate);
