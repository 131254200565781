import React from "react";
import * as _ from "lodash";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";

const FilterClients = ({ groupData, onChangeFilterByGroup }: any) => {
    if (groupData && groupData.getAllGroupClients && !_.isEmpty(groupData.getAllGroupClients.record)) {
        return (
            <CRow>
                <CCol xs="12" sm="auto">
                    <CFormGroup className="form-filter">
                        <CSelect className="select-filter" name="byGroup" id="records" onChange={(e: any) => onChangeFilterByGroup(e.target.value)}>
                            <option value="">All Group</option>
                            {groupData.getAllGroupClients.record.map((o: any) => (
                                <option value={o.id}>{o.name}</option>
                            ))}
                        </CSelect>
                    </CFormGroup>
                </CCol>
            </CRow>
        );
    }
    return null;
};

export default FilterClients;
