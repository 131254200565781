import {
  ApolloClient,
  NormalizedCacheObject
} from '@apollo/client';
import { Const } from '../constants'
import { cache } from './apolloCache';
import typeDefs from './typeDefs'
import { AuthHelpers } from 'helpers'
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createUploadLink({
  uri: Const.serverUrl + Const.prefix,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookie if it exists
  const token = AuthHelpers.getCookie('jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `x-access-token ${token}` : "",
    }
  }
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  typeDefs,
  resolvers: {},
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all"
    },
    query: {
      errorPolicy: "all"
    },
    mutate: {
      errorPolicy: "all"
    }
  }
});

export default client;