import React from "react";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";

const NoItemCustom = ({ loading }: any) => {
    const { t } = useTranslation();
    if (loading || _.isUndefined(loading)) return <div className="text-center my-5">{" "}</div>;
    return (
        <div className="text-center my-5">
            {t("AdminLayout.Message.Empty")}
        </div>
    );
};

export default NoItemCustom;
