import React from "react";
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";
import { toNumber } from 'lodash';

const FilterByStatus = (props: any) => {
    const { onChangeFilterByStatus, value, t } = props;
    return (
        <CRow>
            <CCol xs="12" sm="auto">
                <CFormGroup className="form-filter showing-record">
                    <CSelect
                        className="select-record"
                        name="byStatus"
                        id="byStatus"
                        onChange={(e: any) => onChangeFilterByStatus(toNumber(e.target.value))}
                        value={value}
                    >
                        <option value="0">{t("AdminLayout.SupportFields.Open Ticket")}</option>
                        <option value="1">{t("AdminLayout.SupportFields.Close Ticket")}</option>
                    </CSelect>
                </CFormGroup>
            </CCol>
        </CRow>
    );
};

export default withTranslation()(FilterByStatus);
