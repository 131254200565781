import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './assets/scss/style.scss';
import NotFound from 'components/UI/NotFound';
import { LoadingPulse } from 'components/Admin/ListView'
import { Const } from 'constants/index';

import { isIE } from 'react-device-detect';
import {
  CCol,
  CContainer,
  CRow
} from '@coreui/react';

// Loading first
const loading = (
  <div className="d-flex justify-content-center">
    <div className="m-5" role="status">
      <LoadingPulse/>
    </div>
  </div>
)

// Lazy load - Code splitting
const Admin: any = React.lazy(() => import('./features/Admin'));
const Login: any = React.lazy(() => import('./features/Auth/Login'));
const ForgotPassword: any = React.lazy(() => import('./features/Auth/ForgotPassword'));
const ConfirmForgotPassword: any = React.lazy(() => import('./features/Auth/ConfirmForgotPassword'));
const CreatePassword: any = React.lazy(() => import('./features/Auth/CreatePassword'));
const CommingSoon: any = React.lazy(() => import('./features/Front/ComingSoon'));
// const RequestForm: any = React.lazy(() => import('./features/Front/RequestForm'));
const ProgramDetail: any = React.lazy(() => import('./features/Front/ProgramDetail'));
const CalendarBooking: any = React.lazy(() => import('./features/Front/CalendarBooking'));
const Programme: any = React.lazy(() => import('./features/Front/Programme'));
const InscriptionAmb: any = React.lazy(() => import('./features/Front/InscriptionAmb'));
const InviteToShare: any = React.lazy(() => import('./features/Front/InviteToShare'));
const ReferenceShare: any = React.lazy(() => import('./features/Front/ReferenceShare'));
const Maintenance: any = React.lazy(() => import('./features/Front/Maintenance'));
const MassMailing: any = React.lazy(() => import('./features/Front/MassMailing'));
const EmailTemplateView: any = React.lazy(() => import('./features/Front/EmailTemplateView'));

function App() {

if(!isIE) 
  return (
    <div className="app">
      <React.Suspense fallback={loading}>
        <BrowserRouter>
          {/* <Header /> */}
          <Switch>
            {/* HOME PAGE */}
            <Route exact path={Const.localesString} component={CommingSoon} />
            {/* <Route exact path={`${Const.localesString}/request-form/:id?`} component={RequestForm} /> */}
            <Route exact path={`${Const.localesString}/programme/:url_encoded`} component={ProgramDetail} />
            <Route exact path={`${Const.localesString}/details_du_programme/:url_encoded`} component={ProgramDetail} />
            <Route exact path={`${Const.localesString}/program`} component={ProgramDetail} />
            <Route exact path={`${Const.localesString}/calendar-booking`} component={CalendarBooking} />
            <Route exact path={`${Const.localesString}/mass-mailing/:url_encoded/:amb_code`} component={MassMailing} />
            <Route exact path={`${Const.localesString}/massmailing/:template/:url_encoded/:amb_code?`} component={MassMailing} />
            <Route exact path={`${Const.localesString}/programme`} component={Programme} />
            <Route exact path={`${Const.localesString}/inscription_ambassadeur/:programme/:url_encoded?`} render={props => <InscriptionAmb {...props}/>} />
            <Route exact path={`${Const.localesString}/partage/:url_encoded/:amb_code`} render={props => <InviteToShare {...props}/>} />
            <Route exact path={`${Const.localesString}/share/:url_encoded/:amb_code`} render={props => <InviteToShare {...props}/>} />
            <Route exact path={`${Const.localesString}/reference/:url_encoded/:amb_code`} render={props => <ReferenceShare {...props}/>} />
            <Route exact path={`${Const.localesString}/ambassador/:url_encoded/:amb_code`} render={props => <EmailTemplateView {...props}/>} />
            <Route exact path={`/maintenance`} component={Maintenance} />
            {/* LOGIN PAGE */}
            <Route exact path={`${Const.localesString}/auth/login`} render={props => <Login {...props}/>} />
            <Route exact path={`${Const.localesString}/auth/forgot-password`} render={props => <ForgotPassword {...props}/>} />
            <Route exact path={`${Const.localesString}/auth/confirm-forgot-password`} render={props => <ConfirmForgotPassword {...props}/>} />
            
            <Route exact path={`${Const.localesString}/auth/login/:url_encoded`} render={props => <Login {...props}/>} />
            
            {/* CREATE PASSWORD PAGE */}
            <Route exact path={`${Const.localesString}/auth/create-password/:id`} render={props => <CreatePassword {...props}/>} />
            
            {/* ADMIN DASHBOARD */}
            <Route path={`${Const.localesString}/admin`} render={props => <Admin {...props}/>} />
            

            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </React.Suspense>
    </div>
  );

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol xs="9" className="coming-soon isie">
            <CRow>
              <CCol xs="6" className="col-xs-12 box-left">
                <img src="https://referezunami.com/images/raf-logo-fr.svg" alt="" />
              </CCol>
              <CCol xs="6" className="col-xs-12">
                <div className="box-right">
                  <h3 className="version">Désolé</h3>
                  <h4>Votre navigateur ne peut supporter ce site web veuillez utiliser Google Chrome, Firefox, Safari ou Edge.</h4>
                  <h4>Sorry, your browser does not support this website please use Google Chrome, Firefox, Safari or Edge.</h4>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )

}

export default App;