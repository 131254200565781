import React from "react";
import { CFormGroup, CSelect } from "@coreui/react";
import { withTranslation } from "react-i18next";
import * as _ from 'lodash';
import { Const } from "constants/index";

const FilterByStatusRef = ({ setFilterBy, t }: any) => {
    return (
        <CFormGroup className="showing-record report-style">
            <CSelect className="select-record" name="status" id="status" onChange={(e: any) => setFilterBy(_.toNumber(e.target.value))}>
                <option value="">{t('AdminLayout.All Status')}</option>
                {
                    _.map(Const.statusReferenceUser, (o, key) => {
                        return <option value={o.id} key={`status-ref-${key}`}>{t(`AdminLayout.Const.statusReferenceUser.${o.name}`)}</option>
                    })
                }
            </CSelect>
        </CFormGroup>
    );
};

export default (withTranslation)()(FilterByStatusRef);
