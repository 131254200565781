export const serverUrl = process.env.REACT_APP_API_ENDPOINT || "https://raf-dev.referezunami.com";
export const canadaPostKey = process.env.CANADAPOST_KEY || "WE49-GE25-EC17-TU77";
export const prefix = "/graphql";
export const tokenPrefix = "x-access-token ";
export const ipMaintenance = ["14.234.7.52", "14.187.117.206", "70.80.170.20"];
export const isMaintenance = false;
export const limitGroupClient = 100;
/* Const Groups */
export const groups = [
    { id: 1, name: "Saiyan", isLogin: true },
    { id: 2, name: "Super Admin", isLogin: true, filterIndex: 5 },
    { id: 3, name: "Director", isLogin: true, filterIndex: 2 },
    { id: 4, name: "Controller", isLogin: true, filterIndex: 3 },
    { id: 5, name: "Vice Director", isLogin: true, filterIndex: 4 },
    { id: 6, name: "Advisor", isLogin: true, filterIndex: 1 },
    { id: 7, name: "Ambassador" },
    { id: 8, name: "Referee" },
];

//rules can be become a advisor
export const canBeAdvisor = [3,5,6];

export const expireDate = 2*3600*24;

export const genders = [
    {name: 'Men', value: 0},
    {name: 'Women', value: 1},
    {name: 'Other', value: 2},
]

export const localesString = '/:lng(fr|en)?';
export const defaultLng = 'fr';
export const locates = ["fr", "en"];

export const phoneTypes = [
    { name: 'Mobile', id: 0 },
    { name: 'Home', id: 1 }
]

export const departments = [
    { name: 'New car', id: 1},
    { name: 'Used car', id: 2},
    { name: 'Service Department', id: 3},
    { name: 'Other', id: 4}
]

export const ConversionAmbassador = [
    { name: 'Yes', id: 1},
    { name: 'No', id: 2},
]

export const AmbassadorStatus = [
    { name: 'Connected', id: 1},
    { name: 'Validate the sale', id: 2},
    { name: 'Pay reward', id: 3},
]

export const statusUser = [
    { name: 'Active', id: 1 },
    { name: 'Inactive', id: 0 },
]

export const languages = [
    { name: 'Français', id: 'fr' },
    { name: 'English', id: 'en' },
]

export const statusReferenceUser = [
    { name: 'Logged', id: 1 },
    { name: 'Sale confirmed', id: 2 },
    { name: 'Reward paid', id: 3 },
    { name: 'Sale Cancelled', id: 4 },
]

export const statusFollowUp = [
    { name: 'Message left', id: 1 },
    { name: 'Not joined', id: 2 },
    { name: 'Reminded', id: 3 },
]

export const pwdDefault = 'Ch@l0tte21$';

export const limitRecord = 10;

export const MainImageOption = [
    {
        value: 1,
        name: 'Nous avons une image à proposer'
    }
]
export const MainImageOption2 = [
    {
        value: 2,
        name: 'Accès à notre banque d\'images, nous fournir le lien url, le nom d\'utilisateur et le code d\'accès'
    }
]
export const MainImageOption3 = [
    {
        value: 3,
        name: 'Nous fournir l\'adresse url de la banque d\'images pour que nous puissons nous inscrire'
    }
]

export const  GiftOption = [
    {
        value: 1,
        name: 'Carte-cadeau de 50 $ du concessionnaire'
    },
    {
        value: 2,
        name: 'Carte-cadeau de 100 $ du concessionnaire'
    },
    {
        value: 3,
        name: 'Autre cadeau'
    }
]

export const  GiftOptionForm = [
    {
        value: 1,
        name: 'Oui'
    },
    {
        value: 2,
        name: 'Non'
    }
]

export const typeCar = [
    {
        id: 1,
        name: "Véhicules neufs"
    },
    {
        id: 2,
        name: "Véhicules d'occasion"
    },
    {
        id: 3,
        name: "Administration/autres"
    }
]

export const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
export const rewardTypes = [
    {name: 'New Car', id: 1},
    {name: 'Used Car', id: 2}
];

export const bgColor = [
    //{ id: '', name: 'Theme' },
    { id: 'red', name: 'Red' },
    { id: 'blue', name: 'Blue' },
    { id: 'blue-gray', name: 'Blue-Grey' },
    // { id: 'yellow', name: 'Yellow' },
    { id: 'orange', name: 'Orange' },
    { id: 'blue-marine', name: 'Blue Marine' },
    { id: 'red-wine', name: 'Red Wine' },
    { id: 'yellow', name: 'Yellow' }
];

export const province = [
    //{ id: '', name: 'Province' },
    { id: 'QC', name: 'Québec' },
    { id: 'AB', name: 'Alberta' },
    { id: 'BC', name: 'Colombie-Britannique' },
    { id: 'MB', name: 'Manitoba' },
    { id: 'NB', name: 'Nouveau-Brunswick' },
    { id: 'NL', name: 'Terre-Neuve-et-Labrador' },
    { id: 'NS', name: 'Nouvelle-Écosse' },
    { id: 'NT', name: 'Territoires du Nord-Ouest' },
    { id: 'NU', name: 'Nunavut' },
    { id: 'ON', name: 'Ontario' },
    { id: 'PE', name: 'Île-du-Prince-Édouard' },
    { id: 'SK', name: 'Saskatchewan' },
    { id: 'YT', name: 'Yukon' }
]

export const Captcha = {
    siteKey: "6LdU9hMaAAAAADTwtvN3F1bdSkTItHrmNNAxFPWr",
    secretKey: "6LdU9hMaAAAAAIEuVL2FcqfKN8f4u3pNKFUamlQB"
}

export const favicons = [
    { value: 1, src: "/images/favicon/favicon_blue_grey.ico"},
    { value: 2, src: "/images/favicon/favicon_blue_marine.ico"},
    { value: 3, src: "/images/favicon/favicon_blue.ico"},
    { value: 4, src: "/images/favicon/favicon_orange.ico"},
    { value: 5, src: "/images/favicon/favicon_red.ico"},
    { value: 6, src: "/images/favicon/favicon_yellow.ico"},
    { value: 7, src: "/images/favicon/favicon.ico"},
]

export const colorGroupClient = "#ee71a9";

export const registeredBy = [
    {name: 'Import', value: 1},
    {name: 'Advisor', value: 2},
    {name: 'Client', value: 3},
    {name: 'Other', value: 4}
]

export const ImportationAmbassadeurs = [
    {
        value: 1,
        name: 'Importation des ambassadeurs par le client'
    }
]
export const ImportationAmbassadeurs2 = [
    {
        value: 2,
        name: 'Importation des ambassadeurs par Serti'
    }
]
export const ImportationAmbassadeurs3 = [
    {
        value: 3,
        name: 'Importation des ambassadeurs par Activix'
    }
]

export const fileTypeExcel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const fileExtensionExcel = '.xlsx';

export const transferRoles = [
    { name: 'Download', id: 0 },
    { name: 'Upload', id: 1 },
]

export const transferTimeOut = 600000; //miliseconds

export const levelOfSupport = [
    { id: 1, name: "High" },
    { id: 2, name: "Medium" },
    { id: 3, name: "Low" },
];

export const monthEN = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
export const monthFR = ["JAN", "FÉV", "MAR", "AVR", "MAI", "JUN", "JUL", "AOÛ", "SEP", "OCT", "NOV", "DEC"];

export const apiKeyTinyMCE = "j9k3nsq0l2a97g3ag14w9zcbvccv3wwo3bnzgnzcx35w9gt5";

export const defaultBgColorNoti = "#f0662f";
export const defaultColorNoti = "#fff";