import React from "react";
import * as _ from "lodash";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";
import { Const } from "constants/index";
import { withTranslation } from "react-i18next";

const FilterUsers = (props: any) => {
    const { groupData, onChangeFilterByGroup, onChangeFilterByClient, filterGroupActive, onChangeFilterByRole, t } = props;
    if (groupData && groupData.getAllGroupClients && !_.isEmpty(groupData.getAllGroupClients.record)) {
        const listClient = filterGroupActive ? _.find(groupData.getAllGroupClients.record, (p: any) => p.id === filterGroupActive) : null;
        return (
            <CRow>
                <CCol xs="12" sm="auto">
                    <CFormGroup className="form-filter">
                        <CSelect className="select-filter" name="byGroup" id="records" onChange={(e: any) => onChangeFilterByGroup(e.target.value)}>
                            <option value="">{t('AdminLayout.All Group')}</option>
                            {groupData.getAllGroupClients.record.map((o: any, key: number) => (
                                <option key={key} value={o.id}>
                                    {o.name}
                                </option>
                            ))}
                        </CSelect>
                    </CFormGroup>
                </CCol>
                <CCol xs="12" sm="auto">
                    <CFormGroup className="form-filter">
                        <CSelect
                            className="select-filter"
                            name="byClient"
                            id="byClient"
                            onChange={(e: any) => onChangeFilterByClient(e.target.value)}
                        >
                            <option value="">{t('AdminLayout.All Client')}</option>
                            {listClient && !_.isEmpty(listClient.clients)
                                ? listClient.clients.map((o: any, key: number) => (
                                      <option key={key} value={o.id}>
                                          {o.name}
                                      </option>
                                  ))
                                : null}
                        </CSelect>
                    </CFormGroup>
                </CCol>
                <CCol xs="12" sm="auto">
                    <CFormGroup className="form-filter">
                        <CSelect className="select-filter" name="byClient" id="byClient" onChange={(e: any) => onChangeFilterByRole(e.target.value)}>
                            <option value="">{t("AdminLayout.All Role")}</option>
                            {Const.groups.map(
                                (o: any, key: number) =>
                                    o.id !== 1 && (
                                        <option key={key} value={o.id}>
                                            {t("AdminLayout.Const.Groups." + o.name)}
                                        </option>
                                    )
                            )}
                        </CSelect>
                    </CFormGroup>
                </CCol>
            </CRow>
        );
    }
    return null;
};

export default withTranslation()(FilterUsers);
