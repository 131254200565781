export const variablesClient = {
    name: "",
    email: "",
    email_sent_from: "",
    url: "",
    url_encoded: "",
    phone: "",
    bgColor: "",
    group_client: "",
    active_language: [],
    address: "",
    city: "",
    province: "",
    postal_code: "",
    favicon: 1
}

export const variablesProgram = {
    program_1_title: "",
    program_1_desc: "",
    program_2_title: "",
    program_2_desc: "",
    program_3_title: "",
    program_3_desc: "",
    program_4_title: "",
    program_4_desc: "",
    howitworks_title: "",
    howitworks_text: "",
    howitworks_amb_rewards_condition: "",
    amb_share_email_invite_page_title: "",
    amb_share_facebook: "",
    faq: [{ title: "", desc: "" }],
    rewards: ""
}

export const client_program = [
    //program_details
    { key: 'program_1_title', group: ['program_details', 'mandrill'] },
    { key: 'program_1_desc', group: ['program_details', 'mandrill'] },
    { key: 'program_2_title', group: ['program_details', 'mandrill'] },
    { key: 'program_2_desc', group: ['program_details', 'mandrill'] },
    { key: 'program_3_title', group: ['program_details', 'mandrill'] },
    { key: 'program_3_desc', group: ['program_details', 'mandrill'] },
    { key: 'program_4_title', group: ['program_details', 'mandrill'] },
    { key: 'program_4_desc', group: ['program_details', 'landing_referee', 'mandrill'] },
    { key: 'howitworks_title', group: ['program_details'] },
    { key: 'howitworks_text', group: ['program_details', 'mandrill'] },
    { key: 'howitworks_amb_rewards_condition', group: ['program_details'] },
    { key: 'amb_share_email_invite_page_title', group: ['program_details'] },
    { key: 'faq', group: ['program_details'] },
    { key: 'faq_img', group: ['program_details'] },
    { key: 'client_banner_header_main', group: ['program_details'] },
    { key: 'referafriend_header_short', grroup: ['program_details', 'mandrill'] },
    { key: 'referafriend_banner_sharing', grroup: ['mandrill'] },
    { key: 'amb_share_facebook', group: ['program_details'] },
    { key: 'rewards', group: ['mandrill'] },
];

export const client_fields = [
    //dynamic
    { key: 'amb_share_email_invite_page_title', group: ['amb_share'] },
    { key: 'howitworks_amb_rewards_table_img', group: ['mandrill'] },
    { key: 'online_purchase_url', group: ['landing_referee'] },
    { key: 'client_banner_header_short', group: ['landing_referee'] }
];

export const YesNoOption = [
    {
        value: 1,
        name: "Oui",
    },
    {
        value: 0,
        name: "Non",
    },
]

//Mass Mailing content fields
export const variablesMassMailing = {
    mass_mailing_subject: "",
    mass_mailing_1_title: "",
    mass_mailing_1_desc: "",
    mass_mailing_2_title: "",
    mass_mailing_2_desc: "",
    mass_mailing_3_title: "",
    mass_mailing_3_desc: "",
    mass_mailing_4_title: "",
    mass_mailing_4_desc: ""
}

export const client_mailing = [
    { key: 'mass_mailing_subject', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_1_title', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_1_desc', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_2_title', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_2_desc', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_3_title', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_3_desc', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_4_title', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_4_desc', group: ['mass_mailing', 'mandrill'] },
    { key: 'mass_mailing_1_img', group: ['mass_mailing','program_details'] },
    { key: 'mass_mailing_2_img', group: ['mass_mailing','program_details'] },
    { key: 'mass_mailing_3_img', group: ['mass_mailing','program_details'] },
    { key: 'mass_mailing_4_img', group: ['mass_mailing','program_details'] },
];