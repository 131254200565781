import React, { useState } from "react";
import * as _ from "lodash";
import { withTranslation } from "react-i18next";
import { CCol, CFormGroup, CRow, CSelect } from "@coreui/react";

const FilterByClient = (props: any) => {
    const { groups, client, setClient, setGroup, t } = props;
    const [clients, setClients] = useState([]);
    const onChangeFilterByGroup = (group: any) => {
        setGroup(group);
        setClient("");
        const groupSelected = _.find(groups.getAllGroupClients.record, (o: any) => o.id === group);
        if (groupSelected && !_.isEmpty(groupSelected.clients)) {
            setClients(groupSelected.clients);
        }
    }
    if (groups && !_.isEmpty(groups)) {
        return (
            <CRow>
                <CCol xs="6" sm="auto">
                    <CFormGroup className="form-filter">
                        <CSelect className="select-filter" name="byGroup" id="records" onChange={(e: any) => onChangeFilterByGroup(e.target.value)}>
                            <option value="">{t('AdminLayout.Select Group')}</option>
                            {groups.getAllGroupClients.record.map((o: any) => (
                                <option value={o.id}>{o.name}</option>
                            ))}
                        </CSelect>
                    </CFormGroup>
                </CCol>
                <CCol xs="6" sm="auto">
                    <CFormGroup className="form-filter">
                        <CSelect className="select-filter" name="byGroup" id="records" onChange={(e: any) => setClient(e.target.value)}>
                            <option value="" selected={client === ""}>{t('AdminLayout.Select Client')}</option>
                            {clients && clients.map((o: any) => (
                                <option value={o.id} selected={client === o.id}>{o.name}</option>
                            ))}
                        </CSelect>
                    </CFormGroup>
                </CCol>
            </CRow>
        );
    }
    return null;
};

export default (withTranslation)()(FilterByClient);
