import React from "react";
import { PulseLoader } from "react-spinners";

const LoadingPulse = ({color}: any) => {
    return (
        <div className="custom-loading">
            <div className="loading-middle">
                <PulseLoader color={color || "#ff7f00"} size={10} />
            </div>
        </div>
    );
};

export default LoadingPulse;